import {
  Listbox,
  ListboxButton,
  ListboxLabel as Label,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { useState, useEffect } from "react";

type Props = {
  label: string;
  items: string[];
  className?: string;
  value: string | null;
  onChange: (value: string | null) => void;
};

export default function CustomSelect({
  label,
  items,
  className,
  value,
  onChange,
}: Props) {
  const [selected, setSelected] = useState<string | null>(
    value !== null && items.includes(value) ? value : null
  );

  useEffect(() => {
    if (value !== null && items.includes(value)) {
      setSelected(value);
    } else {
      setSelected(null);
    }
  }, [value, items]);

  const handleChange = (newValue: string | null) => {
    setSelected(newValue);
    onChange(newValue);
  };

  return (
    <div className={twMerge("flex flex-col", className)}>
      <Listbox value={selected} onChange={handleChange}>
        <Label className="block text-sm text-start font-medium text-gray-900">
          {label}
        </Label>
        <div className="relative mt-2">
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm">
            <span className="block truncate">
              {selected === null ? "Nada seleccionado" : selected}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {/* Null option */}
            <ListboxOption
              value={null}
              className={({ active }) =>
                `relative text-start cursor-default select-none py-2 pl-3 pr-9 ${
                  active ? "bg-blue-600 text-white" : "text-gray-900"
                }`
              }
            >
              <span className="block truncate">Nada seleccionado</span>
              {selected === null && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                  <CheckIcon
                    className="h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                </span>
              )}
            </ListboxOption>

            {items.map((item, idx) => (
              <ListboxOption
                key={idx}
                value={item}
                className={({ active }) =>
                  `relative text-start cursor-default select-none py-2 pl-3 pr-9 ${
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  }`
                }
              >
                <span className="block truncate">{item}</span>
                {selected === item && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <CheckIcon
                      className="h-5 w-5 text-blue-600"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
}
