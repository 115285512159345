import React, { useEffect } from "react";
import DashboardLayout from "../shared/components/layout/dashboard-layout";
import CustomInput from "../shared/components/general/custom-input";
import { useAuthContext } from "../context/AuthContext";
import CustomButton from "../shared/components/general/custom-button";
import { isValidPhoneNumber } from "./utils/user-utils";
import apiService from "../services/api-service";
import toast from "react-hot-toast";
import ChangePwdDialog from "./components/change-pwd-dialog";

function MyAccountPage() {
  const { user, setUser } = useAuthContext();
  const [loading, setLoading] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [userData, setUserData] = React.useState({
    name: user?.name,
    lastName: user?.lastName,
    phoneNumber: user?.phoneNumber,
  });
  const [canUpdate, setCanUpdate] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState("");

  useEffect(() => {
    if (
      userData.name?.trimEnd() !== user?.name ||
      userData.lastName?.trimEnd() !== user?.lastName ||
      userData.phoneNumber?.trimEnd() !== user?.phoneNumber
    ) {
      setCanUpdate(true);
    } else {
      setCanUpdate(false);
    }
    if (!isValidPhoneNumber(userData.phoneNumber!.trimEnd())) {
      return setPhoneError(
        "Numero invalido. Debe contener: (+código país seguido del número)"
      );
    }
    setPhoneError("");
  }, [userData, user]);

  async function handleUpdate(e: any) {
    e.preventDefault();
    if (phoneError) return;
    setLoading(true);

    const { data, error } = await apiService.put(`users/${user?.id}`, userData);

    if (error) {
      setLoading(false);

      return toast.error("Error al actualizar la información");
    }

    setUser(data);
    toast.success("Información actualizada correctamente");
    setLoading(false);
  }

  return (
    <DashboardLayout showSidebar={false}>
      <form className="mt-20 overflow-hidden" onSubmit={handleUpdate}>
        <div className="space-y-12">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Mi Cuenta
            </h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Aqui podras consultar y actualizar los datos de tu cuenta
            </p>

            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Correo electronico:{" "}
              <span className="font-bold">{user?.email} </span>
            </p>
            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Nombres
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <CustomInput
                    value={userData?.name}
                    onChange={(e) => {
                      setUserData({ ...userData, name: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Apellidos
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <CustomInput
                    value={userData?.lastName}
                    onChange={(e) => {
                      setUserData({ ...userData, lastName: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Numero de telefono
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 w-80">
                  <CustomInput
                    value={userData?.phoneNumber}
                    error={phoneError}
                    onChange={(e) => {
                      setUserData({ ...userData, phoneNumber: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <CustomButton
            title="Cambiar Contraseña"
            filled={false}
            onClick={() => setIsDialogOpen(true)}
          />
          <CustomButton
            title="Guardar cambios"
            disabled={!canUpdate}
            submit
            loading={loading}
          />
        </div>
      </form>{" "}
      <ChangePwdDialog open={isDialogOpen} setIsOpen={setIsDialogOpen} />
    </DashboardLayout>
  );
}

export default MyAccountPage;
