import React from "react";

function LoadingCategoryCard() {
  return (
    <div className="overflow-hidden rounded-xl border border-gray-200 w-80 animate-pulse">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="h-4 bg-gray-200 rounded w-24"></div>
        <div className="ml-auto flex space-x-2">
          <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
        <div className="flex justify-between gap-x-4 py-3">
          <div className="h-4 bg-gray-200 rounded w-32"></div>
          <div className="flex space-x-2">
            <div className="h-5 w-5 bg-gray-200 rounded"></div>
            <div className="h-5 w-5 bg-gray-200 rounded"></div>
          </div>
        </div>
      </dl>
    </div>
  );
}

export default LoadingCategoryCard;
