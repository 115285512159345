import React, { useEffect } from "react";
import DashboardLayout from "../../shared/components/layout/dashboard-layout";
import {
  LoadingSearchContainer,
  SearchContainer,
} from "../components/search-container";
import { useSearchParams } from "react-router-dom";
import apiService from "../../services/api-service";
import { Section } from "../../shared/types/section";

function SearchPage() {
  let [searchParams] = useSearchParams();
  const [results, setResults] = React.useState<Section[] | null>(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    async function handleSearch() {
      setLoading(true);
      const { data, error } = await apiService.get<Section[]>("sections", {
        keywords: searchParams.get("query"),
      });
      setResults(error ? null : data!);
      setLoading(false);
    }
    handleSearch();
  }, [searchParams]);

  if (loading) {
    return (
      <DashboardLayout showSidebar={false}>
        <ul className=" my-10 space-y-5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <LoadingSearchContainer key={item} />
          ))}
        </ul>
      </DashboardLayout>
    );
  }

  if (!results || results.length === 0) {
    return (
      <DashboardLayout showSidebar={false}>
        <p className="mt-10">No se encontraron resultados</p>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout showSidebar={false}>
      <ul className="my-10 space-y-5">
        {results?.map((section) => (
          <SearchContainer key={section.id} section={section} />
        ))}
      </ul>
    </DashboardLayout>
  );
}

export default SearchPage;
