import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

type Props = {
  initalValue?: string | null;
};

function SearchBar({ initalValue }: Props) {
  const [search, setSearch] = useState(initalValue || "");
  const navigate = useNavigate();

  const handleSearch = (e: any) => {
    e.preventDefault();

    if (search === "") {
      return;
    }

    navigate(`/busqueda?query=${search}`);
  };

  return (
    <form
      className="flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"
      onSubmit={handleSearch}
    >
      <div className="w-full sm:max-w-xs">
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </div>
          <input
            placeholder="¿Como te podemos ayudar?"
            className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pantone sm:text-sm sm:leading-6"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
}

export default SearchBar;
