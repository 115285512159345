import { useState } from "react";
import DashboardTopbar from "./dashboard-topbar";
import NavBar from "./nav-bar";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  showSidebar?: boolean;
  isAdmin?: boolean;
  renderCreateButton?: boolean;
};

export default function DashboardLayout({
  children,
  showSidebar = true,
  isAdmin = false,
  renderCreateButton = false,
}: Props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(showSidebar);

  return (
    <div
      className={twMerge(
        "flex",
        isAdmin ? "h-screen overflow-hidden" : "min-h-screen overflow-auto"
      )}
    >
      {showSidebar && (
        <NavBar open={isSidebarOpen} setOpen={setIsSidebarOpen} />
      )}

      <div
        className={twMerge(
          "flex-1 flex flex-col transition-[padding-left] duration-300",
          isSidebarOpen && showSidebar ? "lg:pl-64" : "lg:pl-0"
        )}
      >
        <div className="sticky top-0 z-10">
          <DashboardTopbar showCreateButton={renderCreateButton} />
        </div>

        <div
          className={twMerge(
            "mx-auto  w-full",
            !isSidebarOpen ? "px-24" : "px-6",
            isAdmin ? "px-20" : ""
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
