import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from "react";
import { twMerge } from "tailwind-merge";

interface KeywordInputProps {
  initialKeywords: string[];
  onKeywordsChange: (keywords: string[]) => void;
}

const KeywordInput: React.FC<KeywordInputProps> = ({
  initialKeywords,
  onKeywordsChange,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>(initialKeywords);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      const newKeyword = inputValue.trim();
      if (!keywords.includes(newKeyword)) {
        setKeywords((prevKeywords) => [...prevKeywords, newKeyword]);
      }
      setInputValue("");
    }
  };

  useEffect(() => {
    onKeywordsChange(keywords);
  }, [keywords, onKeywordsChange]);

  const removeKeyword = (keywordToRemove: string) => {
    setKeywords((prevKeywords) =>
      prevKeywords.filter((keyword) => keyword !== keywordToRemove)
    );
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        className={twMerge(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 sm:text-sm sm:leading-6 p-2"
        )}
        placeholder="Escribe una palabra clave y presiona Enter"
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
      <div className="flex flex-wrap gap-2 mt-2">
        {keywords.map((keyword, index) => (
          <span
            key={index}
            className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800"
          >
            {keyword}
            <button
              type="button"
              className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
              onClick={() => removeKeyword(keyword)}
            >
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default KeywordInput;
