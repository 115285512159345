import { useCallback, useEffect, useState } from "react";
import UserDrawer from "./components/user-drawer";
import UsersTable from "./components/users-table";
import User from "../shared/types/user";
import CustomButton from "../shared/components/general/custom-button";
import apiService from "../services/api-service";
import toast from "react-hot-toast";
import UsersTablePlaceholder from "./components/loading-table";
import Pagination from "../shared/components/general/Pagination";

function UsersPage() {
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [users, setUsers] = useState<User[] | null | undefined>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 5;

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const { data, error } = await apiService.get("users", {
      page: currentPage,
      limit: itemsPerPage,
    });
    if (error) {
      setLoading(false);
      toast.error("Error al cargar los usuarios");
      return;
    }
    setUsers(data.users);
    setTotalItems(data.total);
    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, fetchUsers]);

  return (
    <>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Gestion De Usuarios
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Aquí puedes ver agergar y editar los usuarios de la plataforma.
              Asi como gestionar los permisos de cada uno.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <CustomButton
              title="Agregar usuario"
              onClick={() => {
                setUserToEdit(null);
                setDrawerOpen(true);
              }}
            />
          </div>
        </div>
        {loading ? (
          <UsersTablePlaceholder />
        ) : (
          <>
            <UsersTable
              onUserClick={(user) => {
                setUserToEdit(user);
                setDrawerOpen(true);
              }}
              users={users || []}
            />
            <div className="-mx-8">
              <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
      <UserDrawer
        isOpen={drawerOpen}
        onUserUpdate={() => fetchUsers()} // Refresh data after update
        onUserCreate={() => fetchUsers()} // Refresh data after creation
        onUserDelete={() => fetchUsers()} // Refresh data after deletion
        onClose={() => setDrawerOpen(false)}
        user={userToEdit}
      />
    </>
  );
}

export default UsersPage;
