import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { Site } from "../../shared/types/sites";

type Props = {
  sites: Site[];
  open: boolean;
  setIsOpen: (open: boolean) => void;
};

function SitesOrganizer({ sites: initialSites, open, setIsOpen }: Props) {
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState(initialSites);

  const handleOnDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const reorderedSections = Array.from(sites);
    const [movedSection] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, movedSection);

    const updatedSites = reorderedSections.map((site, index) => ({
      ...site,
      order: index + 1,
    }));

    setSites(updatedSites);
  };

  async function saveChanges() {
    if (sites === initialSites) {
      setIsOpen(false);
      return;
    }

    setLoading(true);

    const payload = sites.map((site) => ({
      id: site.id,
      order: site.order,
    }));

    const { error } = await apiService.post("sites/batch-site-update", payload);

    if (error) {
      setLoading(false);
      toast.error(error);
      return;
    }
    setLoading(false);
    window.location.reload();
  }

  function reset() {
    setSites(initialSites);
    setIsOpen(false);
  }

  return (
    <CustomDialog
      dialogTitle="Organizar Sitios"
      open={open}
      setOpen={setIsOpen}
      onClose={reset}
    >
      <div className="container mx-auto max-w-md max-h-80 overflow-y-auto">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <ul
                className="space-y-2"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sites.map((site, index) => (
                  <Draggable
                    key={site.id}
                    draggableId={site.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        className={`p-4 bg-white rounded shadow cursor-move border ${
                          snapshot.isDragging ? "bg-gray-100" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {site.name}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-2">
        <CustomButton title="Cancelar" filled={false} onClick={reset} />
        <CustomButton
          title="Guardar cambios"
          onClick={saveChanges}
          loading={loading}
        />
      </div>
    </CustomDialog>
  );
}

export default SitesOrganizer;
