import React from "react";
import DashboardLayout from "../shared/components/layout/dashboard-layout";
import AdminLayout from "./components/admin-nav";

function AdminPage() {
  return (
    <DashboardLayout isAdmin showSidebar={false}>
      <AdminLayout />
    </DashboardLayout>
  );
}

export default AdminPage;
