import React, { useState } from "react";
import CustomDialog from "./custom-dialog";
import CustomButton from "./custom-button";

type Props = {
  handleDelete: () => Promise<void>;
  isDeleteDialogOpen: boolean;
  setDeleteDialogOpen: (open: boolean) => void;
  warnText?: string;
};

function DeleteDialog({
  handleDelete,
  isDeleteDialogOpen,
  setDeleteDialogOpen,
  warnText,
}: Props) {
  function getWarnText() {
    return warnText || "Esta opción es irreversible";
  }

  const [loading, setLoading] = useState(false);

  return (
    <CustomDialog
      open={isDeleteDialogOpen}
      setOpen={setDeleteDialogOpen}
      dialogTitle="¿Seguro?"
      onClose={() => setDeleteDialogOpen(false)}
    >
      <p className="my-3">{getWarnText()}</p>
      <div className="space-x-3">
        <CustomButton
          title="Cancelar"
          onClick={() => setDeleteDialogOpen(false)}
          filled={false}
        />
        <CustomButton
          title="Eliminar"
          submit
          onClick={async () => {
            setLoading(true);
            await handleDelete();
            setLoading(false);
          }}
          filled={false}
          loading={loading}
          className="border-red-600 text-red-600"
        />
      </div>
    </CustomDialog>
  );
}

export default DeleteDialog;
