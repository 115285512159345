import React, { useEffect, useState } from "react";
import DashboardLayout from "../shared/components/layout/dashboard-layout";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CatalogeHeader from "./components/catalogue-header";
import CatalogueContent from "./components/catalogue-content";
import CatalogueMedia from "./components/catalogue-media";
import { CatalogueItem } from "../shared/types/catalogues";
import apiService from "../services/api-service";
import { LoadingCataloguePage } from "./components/loading-catalogue";
import DeleteDialog from "../shared/components/general/delete-dialog";
import { useAuthContext } from "../context/AuthContext";
import { useCanEditCatalogue } from "../wiki/hooks/use-canEdit";
import toast from "react-hot-toast";

function CataloguePage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [catalogueItem, setCatalogueItem] = useState<CatalogueItem | null>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();
  const canEdit = useCanEditCatalogue(catalogueItem?.catalogue!);

  useEffect(() => {
    async function getCatalogueItem() {
      setLoading(true);
      const id = searchParams.get("id");
      if (id) {
        const catalogId = parseInt(id);

        if (isNaN(catalogId)) {
          navigate("/start");
          return;
        }

        const { data, error } = await apiService.get<CatalogueItem>(
          `catalogues/${catalogId}/items/${id}`
        );

        if (error) {
          toast.error("No se pudo cargar el catálogo");
          navigate("/start");
          return;
        }

        const parentSite = data!.catalogue?.site;

        if (!parentSite) {
          navigate("/start");
          return;
        }

        if (!parentSite.public && !user?.permission.canViewPrivateSites) {
          navigate("/start");
          return;
        }

        setCatalogueItem(data!);
        setLoading(false);
        return;
      }

      navigate("/start");
    }
    getCatalogueItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchParams, user]);

  if (loading) {
    return (
      <DashboardLayout showSidebar={false}>
        <LoadingCataloguePage />
      </DashboardLayout>
    );
  }

  const handleBackNavigation = () => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <DashboardLayout showSidebar={false}>
      <div className="py-10">
        {/* title and backbtn  */}
        <div className="w-full flex items-center border-b-pantone border-b-[.5px] pb-5 mb-5">
          <ArrowLeftIcon
            className="w-5 h-5 cursor-pointer"
            onClick={handleBackNavigation}
          />
          <p className="mx-auto text-2xl font-medium">Detalles</p>
          {canEdit && (
            <div
              className="flex gap-x-3"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              <TrashIcon className="w-5 h-5 cursor-pointer text-pantone" />
            </div>
          )}
        </div>

        {/* content */}
        <div className="flex flex-col gap-y-2">
          <CatalogeHeader
            catalogueItem={catalogueItem!}
            onCatalogueItemUpdated={(cat) => setCatalogueItem(cat)}
          />
          <CatalogueContent catalogueItem={catalogueItem!} />
          <CatalogueMedia catalogueItem={catalogueItem!} />
        </div>

        <DeleteDialog
          handleDelete={async () => {
            apiService.delete(
              `catalogues/${catalogueItem?.catalogue?.id}/items/${catalogueItem?.id}`
            );

            handleBackNavigation();
          }}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setDeleteDialogOpen={setIsDeleteDialogOpen}
        />
      </div>
    </DashboardLayout>
  );
}

export default CataloguePage;
