import Cookies from "js-cookie";
import { LoginResponse } from "../types/auth";

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function saveAuthCookies(data: LoginResponse) {
  const accessTokenExpires = data!.accessToken.payload.exp;
  const expirationDate = new Date(accessTokenExpires * 1000);

  Cookies.set("email", data.idToken.payload.email, {
    expires: addDays(expirationDate, 1),
  });
  Cookies.set("accessToken", data.accessToken.jwtToken, {
    expires: expirationDate,
  });
  Cookies.set("refreshToken", data.refreshToken.token, {
    expires: addDays(expirationDate, 1),
  });
}

export function clearCookies() {
  Cookies.remove("email");
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
}
