function getFileLogoByFileName(fileName: string) {
  const fileExtention = getFileExtention(fileName);

  const basePath = "/images/";

  const fileMappings: any = {
    pdf: basePath + "pdf-icon.png",
    doc: basePath + "docs-icon.png",
    docx: basePath + "doc-icon.png",
    xls: basePath + "xlsx-icon.png",
    xlsx: basePath + "xlsx-icon.png",
    ppt: basePath + "ppt-icon.png",
    pptx: basePath + "ppt-icon.png",
    ppto: basePath + "ppt-icon.png",
    png: basePath + "image-icon.png",
    jpg: basePath + "image-icon.png",
    jpeg: basePath + "image-icon.png",
    gif: basePath + "image-icon.png",
    svg: basePath + "image-icon.png",
    mp4: basePath + "video-icon.png",
    mov: basePath + "video-icon.png",
    webp: basePath + "image-icon.png",
    potx: basePath + "ppt-icon.png",
  };

  return fileMappings[fileExtention!] || basePath + "file-icon.webp";
}

function getFileExtention(fileName: string) {
  const ext = fileName.split(".").pop();
  return ext;
}

export { getFileLogoByFileName };
