import React from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import { useAuthContext } from "../../context/AuthContext";
import { verifyPasswords } from "../../auth/utils/auth-utlis";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";

type props = {
  open: boolean;
  setIsOpen: (open: boolean) => void;
};

function ChangePwdDialog({ open, setIsOpen }: props) {
  const { user } = useAuthContext();
  const [loading, setLoading] = React.useState(false);
  const [passwordForm, setPasswordForm] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function reset() {
    setPasswordForm({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setCurrentPwdError("");
    setPwdError("");
  }

  const [currentPwdError, setCurrentPwdError] = React.useState("");
  const [pwdError, setPwdError] = React.useState("");

  async function handleSubmit(e: any) {
    e.preventDefault();
    const pwdError = verifyPasswords(
      passwordForm.newPassword,
      passwordForm.confirmPassword
    );
    if (pwdError !== null) {
      setPwdError(pwdError);
      return;
    }
    setPwdError("");
    setLoading(true);

    const { error } = await apiService.post("auth/changePassword", {
      email: user?.email,
      oldPassword: passwordForm.currentPassword,
      newPassword: passwordForm.newPassword,
    });

    if (error) {
      setLoading(false);
      setCurrentPwdError("Clave actual incorrecta");
      return;
    }

    setLoading(false);
    setIsOpen(false);
    reset();
    toast.success("Clave cambiada con exito");
  }

  return (
    <CustomDialog
      dialogTitle="Cambiar Clave"
      open={open}
      setOpen={setIsOpen}
      onClose={reset}
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <CustomInput
            placeholder="Clave actual"
            type="password"
            required
            error={currentPwdError}
            value={passwordForm.currentPassword}
            onChange={(e) =>
              setPasswordForm({
                ...passwordForm,
                currentPassword: e.target.value,
              })
            }
          />
          <CustomInput
            placeholder="Nueva clave"
            type="password"
            required
            value={passwordForm.newPassword}
            onChange={(e) =>
              setPasswordForm({
                ...passwordForm,
                newPassword: e.target.value,
              })
            }
          />
          <CustomInput
            placeholder="Confirmar clave"
            type="password"
            error={pwdError}
            required
            value={passwordForm.confirmPassword}
            onChange={(e) =>
              setPasswordForm({
                ...passwordForm,
                confirmPassword: e.target.value,
              })
            }
          />
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-2">
          <CustomButton
            title="Cancelar"
            filled={false}
            onClick={() => setIsOpen(false)}
          />
          <CustomButton title="Guardar cambios" submit loading={loading} />
        </div>
      </form>
    </CustomDialog>
  );
}

export default ChangePwdDialog;
