import { Section } from "../../shared/types/section";

export const getSectionKeywords = (section: Section) => {
  const result: string[] = [];

  section.files.forEach((file) => {
    const keywords = file.keywords;
    if (keywords) {
      result.push(...keywords);
    }
  });

  section.links.forEach((link) => {
    const keywords = link.keywords;
    if (keywords) {
      result.push(...keywords);
    }
  });

  return result.join(", ");
};
