import React from "react";
import { Site } from "../../shared/types/sites";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import apiService from "../../services/api-service";
import { useSitesContext } from "../../context/SitesContext";

type Props = {
  sites: Site[];
  onEditButtonClick: (site: Site) => void;
};

function SitesTable({ sites, onEditButtonClick }: Props) {
  const { onSiteUpdated } = useSitesContext();

  async function updateSiteVisibility(site: Site) {
    onSiteUpdated({ ...site, visible: !site.visible });
    await apiService.put(`sites/${site.id}`, {
      visible: !site.visible,
    });
  }

  function VisibilityButton({ site }: { site: Site }) {
    return (
      <button className="mx-3" onClick={() => updateSiteVisibility(site)}>
        {site.visible ? (
          <EyeIcon className="w-4" />
        ) : (
          <EyeSlashIcon className="w-4" />
        )}
      </button>
    );
  }

  function getSiteHref(site: Site) {
    const origin = window.location.origin;
    return `${origin}/${site.path}`;
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Nombre
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Descripcion breve
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  URL
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Visible
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sites.map((site) => (
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {site.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate  max-w-24">
                    {site.description}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-500  underline cursor-pointer">
                    <a href={getSiteHref(site)} target="__blank">
                      /{site.path}
                    </a>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <VisibilityButton site={site} />
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <button
                      className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      onClick={() => onEditButtonClick(site)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SitesTable;
