import DashboardLayout from "../../shared/components/layout/dashboard-layout";
import { SectionContainer } from "./SectionContainer";
import { useBasePageContext } from "../../context/BasePageContext";
import { LoadingSection } from "./LoadingSection";
import { useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";
import CatalogueTable from "../../catalogues/components/catalogue-table";
import EmptyBasePage from "./EmptyBasePage";
import useCanEdit from "../hooks/use-canEdit";
import OrganizeSectionOrder from "./SectionsDragAndDrop";

function BasePage() {
  const { sections, error, loading, catalogues } = useBasePageContext();
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const isEmpty = !sections || sections.length === 0;

  const canEdit = useCanEdit();

  function Content() {
    if (loading) {
      return [1, 2].map((i) => <LoadingSection key={i} />);
    }

    if (error) {
      return <p>Error contacta al administrador</p>;
    }

    return (
      <div className="flex flex-col">
        {canEdit && !loading && !error && sections && sections?.length > 1 && (
          <>
            <CustomButton
              title="Organizar secciones"
              className="mt-5 self-end"
              onClick={() => setIsOrderDialogOpen(true)}
              filled={false}
            />
            <OrganizeSectionOrder
              sections={sections!}
              open={isOrderDialogOpen}
              setIsOpen={setIsOrderDialogOpen}
            />
          </>
        )}

        {sections?.map((section) => (
          <SectionContainer key={section.id} section={section} />
        ))}
        <div className={sections?.length === 0 ? "mt-10" : ""}>
          {catalogues?.map((catalogue) => (
            <CatalogueTable key={catalogue.id} catalogue={catalogue} />
          ))}
        </div>

        {isEmpty && <EmptyBasePage />}
      </div>
    );
  }

  return (
    <DashboardLayout renderCreateButton={canEdit}>
      <div className="mb-20 space-y-20">
        <Content />
      </div>
    </DashboardLayout>
  );
}

export default BasePage;
