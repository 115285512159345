import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Category } from "../../shared/types/categories";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import DeleteDialog from "../../shared/components/general/delete-dialog";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useCategoriesContext } from "../hooks/use-categories";

type Props = {
  categories: Category[];
  level: number;
  openActionModal: (level: number, category: Category | null) => void;
  onSelectedCategoryChange: (category: Category | null) => void;
};

function CategoryCard({
  categories,
  level,
  openActionModal,
  onSelectedCategoryChange,
}: Props) {
  const { deleteCategory } = useCategoriesContext();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedForDelete, setSelectedForDelete] = useState<Category | null>(
    null
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  function getNameByLevel() {
    switch (level) {
      case 1:
        return "Artefacto";
      case 2:
        return "Clasificación";
      case 3:
        return "Subclasificación";
      default:
        return "Artefacto";
    }
  }

  function CategoryLine({ category }: { category: Category }) {
    const isSelected = selectedCategory?.id === category.id;

    return (
      <div
        className={twMerge(
          "flex justify-between gap-x-4 py-3 cursor-pointer px-2 rounded-lg",
          isSelected && "bg-gray-50 text-blue-600",
          level === 3 && "cursor-default"
        )}
        onClick={() => {
          if (level === 3) {
            return;
          }

          if (isSelected) {
            setSelectedCategory(null);
            onSelectedCategoryChange(null);
          } else {
            onSelectedCategoryChange(category);
            setSelectedCategory(category);
          }
        }}
      >
        <div
          className={twMerge(
            "font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap",
            isSelected && "text-blue-500"
          )}
        >
          {category.name}
        </div>
        <div className="flex space-x-4">
          <PencilIcon
            className="text-blue-500 w-4 h-4 cursor-pointer"
            onClick={() => openActionModal(level, category)}
          />
          <TrashIcon
            className="text-red-500 w-4 h-4 cursor-pointer"
            onClick={() => {
              setSelectedForDelete(category);
              setIsDeleteDialogOpen(true);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className=" rounded-xl border border-gray-200  w-80 overflow-y-auto max-h-[550px]">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-sm/6 font-medium text-gray-900">
          {getNameByLevel()}
        </div>
        <Menu as="div" className="relative ml-auto">
          <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>
          <MenuItems
            transition
            className="absolute  z-50 right-0 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <MenuItem>
              <button
                onClick={() => openActionModal(level, null)}
                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
              >
                agregar
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
      <dl className="-my-3 divide-y   divide-gray-100 px-6 py-4 text-sm/6">
        {categories?.map((category) => (
          <CategoryLine key={category.id} category={category} />
        ))}

        {!categories?.length && <p className="my-2">Lista vacía</p>}
      </dl>
      <DeleteDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDelete={async () => {
          const { error } = await apiService.delete(
            `categories/${selectedForDelete?.id}`
          );

          if (error) {
            toast.error("Esta categoria esta en uso");
            return;
          }

          deleteCategory(selectedForDelete?.id!);
          setIsDeleteDialogOpen(false);
          setSelectedForDelete(null);
        }}
        warnText="Tambien se eliminaran las subclasificaciones"
        setDeleteDialogOpen={setIsDeleteDialogOpen}
      />
    </div>
  );
}

export default CategoryCard;
