import React from "react";
import AuthLayout from "./components/auth-layout";
import CustomInput from "../shared/components/general/custom-input";
import CustomButton from "../shared/components/general/custom-button";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../services/api-service";
import toast from "react-hot-toast";
import { verifyPasswords } from "./utils/auth-utlis";

function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [newPwd, setNewPwd] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [pwdError, setPwdError] = React.useState("");
  const [codeError, setCodeError] = React.useState("");

  const navigate = useNavigate();

  async function onSmubmitSendEmail(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    const { error } = await apiService.post("auth/forgotPassword", {
      email,
    });

    if (error) {
      setLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }

    setEmailSent(true);
    setLoading(false);
  }

  async function onSmubmitChangePassword(e: React.FormEvent) {
    e.preventDefault();
    const pwdError = verifyPasswords(newPwd, confirm);
    if (pwdError !== null) {
      setPwdError(pwdError);
      return;
    }

    setLoading(true);

    const { error } = await apiService.post("auth/confirmPassword", {
      email,
      verificationCode: code,
      newPassword: newPwd,
    });

    if (error) {
      setLoading(false);
      setCodeError("Codigo incorrecto");
      return;
    }

    toast.success("Clave cambiada con exito");
    navigate("/");
  }

  return (
    <AuthLayout>
      <div className="flex flex-col   w-full items-center justify-center flex-1">
        <h1 className="text-2xl mb-1.5">Recupera tu Clave</h1>
        <p className="text-base mb-10">
          Se enviara un correo con tu codigo de recuperacion
        </p>

        <form className="" onSubmit={onSmubmitSendEmail}>
          <CustomInput
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Correo Electronico"
            className="w-60 mb-4"
          />
          {!emailSent && (
            <CustomButton
              title="Enviar correo"
              filled
              className="w-60"
              submit
              loading={loading}
            />
          )}
        </form>

        {emailSent && (
          <form className="" onSubmit={onSmubmitChangePassword}>
            <CustomInput
              type="number"
              required
              value={code}
              error={codeError}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Codigo de recuperacion"
              className="w-60 mb-4"
            />
            <CustomInput
              type="password"
              required
              value={newPwd}
              onChange={(e) => setNewPwd(e.target.value)}
              placeholder="Nueva clave"
              className="w-60 mb-4"
            />
            <CustomInput
              type="password"
              required
              error={pwdError}
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              placeholder="Confirmar clave"
              className="w-60 mb-4"
            />
            <CustomButton
              title="Cambiar"
              filled
              className="w-60"
              submit
              loading={loading}
            />
          </form>
        )}

        <Link to="/" className="underline text-sm text-gray-600 mt-2 ">
          Regresar a inicio
        </Link>
      </div>
    </AuthLayout>
  );
}

export default ForgotPassword;
