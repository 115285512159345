import React, { useEffect, useState } from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import { Category } from "../../shared/types/categories";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useCategoriesContext } from "../hooks/use-categories";

type Props = {
  open: boolean;
  level: number;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  onCategoryCreated: (category: Category) => void;
  onCategoryUpdated: (category: Category) => void;
  category?: Category | null;
  selectedParentCategory?: Category | null;
  selectedSubchildCategory?: Category | null;
};

function CategoriesDialog({
  open,
  level,
  setOpen,
  onClose,
  onCategoryCreated,
  onCategoryUpdated,
  category,
  selectedParentCategory,
  selectedSubchildCategory,
}: Props) {
  const [name, setName] = useState("");
  const { categories } = useCategoriesContext();
  const [loading, setLoading] = useState(false);

  const editMode = category !== null && category !== undefined;

  function categoryExists() {
    return categories!.some((c) => c.name === name);
  }

  function getParentId() {
    if (level === 1) {
      return null;
    }

    if (level === 2) {
      return selectedParentCategory?.id || null;
    }

    if (level === 3) {
      return selectedSubchildCategory?.id || selectedParentCategory?.id || null;
    }
  }

  useEffect(() => {
    if (category) {
      setName(category.name || "");
    } else {
      setName("");
    }
  }, [category]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (categoryExists()) {
      toast.error("Ya existe una categoría con ese nombre");
      setLoading(false);
      return;
    }

    if (editMode) {
      const { data, error } = await apiService.put(
        `categories/${category!.id}`,
        {
          name,
          level,
        }
      );

      if (error) {
        toast.error(error);
        return;
      }

      onCategoryUpdated(data);
    } else {
      const { data, error } = await apiService.post("categories", {
        name,
        level,
        parentId: getParentId(),
      });

      if (error) {
        toast.error(error);
        return;
      }
      onCategoryCreated(data);
      setOpen(false);
    }

    setLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);

    setTimeout(() => {
      onClose();
    }, 200);
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      dialogTitle="Categoría"
      onClose={handleClose}
    >
      <form className="space-y-4" onSubmit={handleSubmit}>
        <CustomInput
          placeholder="Nombre"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
        <div className="flex justify-end space-x-4">
          <CustomButton
            title="Cancelar"
            onClick={handleClose}
            disabled={loading}
            filled={false}
          />
          <CustomButton
            title={editMode ? "Actualizar" : "Crear"}
            submit
            filled={false}
            loading={loading}
          />
        </div>
      </form>
    </CustomDialog>
  );
}

export default CategoriesDialog;
