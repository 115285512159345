import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import CustomDialog from "../../shared/components/general/custom-dialog";
import { Section } from "../../shared/types/section";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";

type Props = {
  sections: Section[];
  open: boolean;
  setIsOpen: (open: boolean) => void;
};

function OrganizeSectionOrder({
  sections: initialSections,
  open,
  setIsOpen,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState(initialSections);

  const handleOnDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const reorderedSections = Array.from(sections);
    const [movedSection] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, movedSection);

    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      order: index + 1,
    }));

    setSections(updatedSections);
  };

  async function saveChanges() {
    if (sections === initialSections) {
      setIsOpen(false);
      return;
    }

    setLoading(true);

    const payload = {
      sections: sections.map((section) => ({
        id: section.id,
        order: section.order,
      })),
    };

    const { error } = await apiService.post(
      "sections/batch-section-update",
      payload
    );

    if (error) {
      setLoading(false);
      toast.error(error);
      return;
    }
    setLoading(false);
    window.location.reload();
  }

  function reset() {
    setSections(initialSections);
    setIsOpen(false);
  }

  return (
    <CustomDialog
      dialogTitle="Organizar Secciones"
      open={open}
      setOpen={setIsOpen}
      onClose={reset}
    >
      <div className="container mx-auto max-w-md max-h-80 overflow-y-auto">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <ul
                className="space-y-2"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sections.map((section, index) => (
                  <Draggable
                    key={section.id}
                    draggableId={section.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        className={`p-4 bg-white rounded shadow cursor-move border ${
                          snapshot.isDragging ? "bg-gray-100" : ""
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {section.title}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-2">
        <CustomButton title="Cancelar" filled={false} onClick={reset} />
        <CustomButton
          title="Guardar cambios"
          onClick={saveChanges}
          loading={loading}
        />
      </div>
    </CustomDialog>
  );
}

export default OrganizeSectionOrder;
