import React from "react";
import { twMerge } from "tailwind-merge";

type CustomTextareaProps = {
  className?: string;
  placeholder?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  disabled?: boolean;
  value?: string;
};

function CustomTextarea({
  className,
  placeholder,
  value,
  required = false,
  onChange,
  disabled,
  error,
}: CustomTextareaProps) {
  return (
    <div className={twMerge(className)}>
      <textarea
        required={required}
        value={value}
        className={twMerge(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 sm:text-sm sm:leading-6 p-2 ring-gray-600",
          error && "ring-pantone mb-1.5"
        )}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        rows={2}
      />
      {error && <p className="text-pantone text-xs">{error}</p>}
    </div>
  );
}

export default CustomTextarea;
