import React, { useEffect, useState } from "react";
import { Site } from "../shared/types/sites";
import { useAuthContext } from "./AuthContext";
import LoadingPage from "../shared/components/general/loading-page";
import apiService from "../services/api-service";

interface ISitesContext {
  sites: Site[];
  loading: boolean;
  onSiteCreated: (site: Site) => void;
  onSiteUpdated: (site: Site) => void;
  onSiteDeleted: (siteId: number) => void;
}

export const SitesContext = React.createContext<ISitesContext>(
  {} as ISitesContext
);
export default function SitesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: authLoading } = useAuthContext();

  function onSiteCreated(site: Site) {
    setSites((prevSites) => [...prevSites, site]);
  }

  function onSiteUpdated(site: Site) {
    setSites((prevSites) =>
      prevSites.map((prevSite) =>
        prevSite.id === site.id ? { ...prevSite, ...site } : prevSite
      )
    );
  }

  function onSiteDeleted(siteId: number) {
    setSites((prevSites) => prevSites.filter((site) => site.id !== siteId));
  }

  useEffect(() => {
    if (authLoading) {
      return;
    }

    if (!user) {
      setLoading(false);
      setSites([]);
      return;
    }

    const fetchSites = async () => {
      setLoading(true);
      const { data, error } = await apiService.get<Site[]>("sites");

      if (error) {
        setLoading(false);
        console.error(error);
        return;
      }

      setSites(data!);
      setLoading(false);
    };

    fetchSites();
  }, [user, authLoading]);

  if (authLoading || loading) {
    return <LoadingPage />;
  }

  return (
    <SitesContext.Provider
      value={{ sites, loading, onSiteCreated, onSiteUpdated, onSiteDeleted }}
    >
      {children}
    </SitesContext.Provider>
  );
}
export const useSitesContext = () => {
  return React.useContext(SitesContext);
};
