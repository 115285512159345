import Cookies from "js-cookie";
import React, { useState } from "react";
import apiService from "../services/api-service";
import User from "../shared/types/user";
import { clearCookies, saveAuthCookies } from "../shared/utils/auth";
import { LoginResponse } from "../shared/types/auth";

interface IAuthContext {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  logOut: () => void;
  loading: boolean;
}

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);

  function setAuthData(authUser?: User | undefined) {
    setUser(authUser);
    setLoading(false);
  }

  async function refreshSession(email: string, refreshToken: string) {
    console.log("Refreshing session...");
    const { data, error } = await apiService.post<LoginResponse>(
      "auth/refreshSession",
      {
        refreshToken,
        email,
      }
    );
    if (error) {
      clearCookies();
      return;
    }
    saveAuthCookies(data!);
    const token = data?.accessToken.jwtToken;
    apiService.authToken = token;
    return token;
  }

  React.useEffect(() => {
    async function initializeUser() {
      let token = Cookies.get("accessToken");
      const refreshToken = Cookies.get("refreshToken");
      const userEmail = Cookies.get("email");

      // no session found
      if (!token && !refreshToken) {
        setAuthData();
        return;
      }

      // sesion must be refreshed
      if (!token && refreshToken) {
        const refreshedToken = await refreshSession(userEmail!, refreshToken);
        if (!refreshedToken) {
          return;
        }
        token = refreshedToken;
      }

      apiService.authToken = token;

      const { error, data } = await apiService.get<User>("auth/me");
      if (error) {
        setAuthData();
        return;
      }
      setAuthData(data);
    }
    initializeUser();
  }, []);

  function logOut() {
    clearCookies();
    setUser(undefined);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};
