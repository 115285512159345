import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "./custom-button";

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-pantone">404</p>
          <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
            No encontramos la página
          </h1>
          <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            La página que buscas no existe o fue eliminada.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <CustomButton onClick={() => navigate("/")} title="Ir a inicio" />
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFoundPage;
