import React from "react";
import CustomInput from "../../shared/components/general/custom-input";
import { RichTextEditor } from "../../shared/components/general/rich-text";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import { Section, SectionImage } from "../../shared/types/section";
import toast from "react-hot-toast";
import { useBasePageContext } from "../../context/BasePageContext";
import { Site } from "../../shared/types/sites";

type Props = {
  backToIdle: () => void;
  newCreatedSection: Section | null | undefined;
  setNewCreatedSection: React.Dispatch<
    React.SetStateAction<Section | null | undefined>
  >;
  site: Site;
  onClose: () => void;
};

export default function CreateSectionForm({
  backToIdle,
  newCreatedSection,
  site,
  setNewCreatedSection,
  onClose,
}: Props) {
  const { onSectionCreated } = useBasePageContext();

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  async function onImageUpload(file: File) {
    const { data, error } = await apiService.uploadFile<SectionImage>(
      `sections/${newCreatedSection!.id}/images`,
      file
    );

    if (error) {
      toast.error("Error subiendo la imagen");
      return "";
    }

    return data?.presignedUrl!;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!title || !content) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    setLoading(true);

    const { data, error } = await apiService.put<Section>(
      `sections/${newCreatedSection?.id}`,
      {
        pageName: site.path,
        title,
        content,
      }
    );

    if (error) {
      setLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }
    setLoading(false);
    onSectionCreated(data!);
    backToIdle();
  }

  return (
    <form className="w-full flex flex-col space-y-5" onSubmit={handleSubmit}>
      <CustomInput
        placeholder="Titulo"
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <RichTextEditor
        initialContent={content}
        onImageUpload={onImageUpload}
        onContentChanged={(s) => setContent(s)}
      />
      <div className="flex self-end space-x-2">
        <CustomButton
          title="Cancelar"
          onClick={async () => {
            onClose();
            backToIdle();
            await apiService.delete(`sections/${newCreatedSection!.id}`);
            setNewCreatedSection(null);
            setContent("");
          }}
          filled={false}
        />
        <CustomButton title="Crear" submit loading={loading} />
      </div>
    </form>
  );
}
