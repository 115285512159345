import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Section } from "../../shared/types/section";
import { getSectionKeywords } from "../utils/search-utils";

type Props = {
  section: Section;
};

export function LoadingSearchContainer() {
  return (
    <li className="flex border-b p-4 ounded-md flex-wrap items-center justify-between sm:flex-nowrap">
      <div className="animate-pulse w-full">
        <div className="h-3 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="flex items-center gap-x-2 max-w-[400px]">
          <div className="h-2 bg-gray-300 rounded w-full"></div>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <div className="h-2 bg-gray-300 rounded w-1/4"></div>
        </div>
      </div>
    </li>
  );
}

export function SearchContainer({ section }: Props) {
  const navigate = useNavigate();

  return (
    <li
      className="flex border-b cursor-pointer   p-4  hover:bg-gray-100 rounded-md flex-wrap items-center justify-between  sm:flex-nowrap"
      onClick={() => navigate(`/${section.pageName}#seccion-${section.id}`)}
    >
      <div>
        <p className="text-sm font-semibold leading-6 text-gray-900">
          <Link
            to={`/${section.pageName}#seccion-${section.id}`}
            className="hover:underline"
          >
            {section.title}
          </Link>
        </p>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p>{section.pageName}</p>

          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="truncate max-w-[800px]	">
            {getSectionKeywords(section)}
          </p>
        </div>
      </div>
      <ChevronRightIcon className="h-5 w-5 text-gray-500" />
    </li>
  );
}
