import React, { useEffect, useState } from "react";
import CustomInput from "../shared/components/general/custom-input";
import CustomButton from "../shared/components/general/custom-button";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../services/api-service";
import { useAuthContext } from "../context/AuthContext";
import User from "../shared/types/user";
import { LoginResponse } from "../shared/types/auth";
import { saveAuthCookies } from "../shared/utils/auth";
import AuthLayout from "./components/auth-layout";

function LoginPage() {
  const navigate = useNavigate();
  const { setUser, user } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (user) {
      navigate("/start");
    }
  }, [user, navigate]);

  function validateFelds() {
    if (!email || !emailRegex.test(email)) {
      setEmailError("Ingresa un correo electronico valido");
      return false;
    }

    setEmailError("");

    if (!password) {
      setPasswordError("Ingresa una contraseña");
      return false;
    }

    setPasswordError("");
    return true;
  }

  async function handleLogin() {
    if (!validateFelds()) {
      return;
    }

    setLoading(true);

    const { error: loginError, data } = await apiService.post<LoginResponse>(
      "auth/login",
      {
        email,
        password,
      }
    );

    if (loginError) {
      if (loginError === "RequiresNewPassword") {
        localStorage.setItem("email", email);
        navigate("activar-cuenta");
        return;
      }

      if (loginError === "AccountIsBlocked") {
        setEmailError("Cuenta bloqueada, contacta al administrador");
        setLoading(false);
        return;
      }

      setPasswordError("Correo o contraseña incorrectos");
      setLoading(false);
      return;
    }

    saveAuthCookies(data!);
    apiService.authToken = data?.accessToken.jwtToken;
    const { error: userError, data: user } = await apiService.get<User>(
      `auth/me`
    );
    if (userError) {
      return;
    }

    setUser(user);
    navigate("/start");
    setLoading(false);
  }

  return (
    <AuthLayout>
      <div className="flex flex-col   w-full items-center justify-center flex-1">
        <h1 className="text-4xl mb-10">Iniciar Sesión</h1>
        <CustomInput
          type="email"
          placeholder="Correo electronico"
          error={emailError}
          className="w-60 mb-4"
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomInput
          type="password"
          placeholder="Clave"
          onChange={(e) => setPassword(e.target.value)}
          className="w-60 mb-4"
          error={passwordError}
        />
        <CustomButton
          className="w-60 mb-4"
          title="Iniciar sesión"
          loading={loading}
          onClick={() => handleLogin()}
        />
        <Link
          to="olvide-clave"
          className="underline text-sm text-gray-600 mb-4 "
        >
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
    </AuthLayout>
  );
}

export default LoginPage;
