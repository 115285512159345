import { useSitesContext } from "../../context/SitesContext";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import { useAuthContext } from "../../context/AuthContext";
import { Catalogue } from "../../shared/types/catalogues";

function useCanEdit() {
  const { sites } = useSitesContext();
  const { user } = useAuthContext();

  const currentSite = sites.find(
    (site) => site.path === window.location.pathname.split("/")[1]
  );

  if (!currentSite) return false;

  if (getRoleByPermissions(user?.permission!) === "super-admin") return true;

  if (currentSite.public) {
    return (
      getRoleByPermissions(user?.permission!) === "documenter" ||
      getRoleByPermissions(user?.permission!) === "private-documenter"
    );
  }

  return getRoleByPermissions(user?.permission!) === "private-documenter";
}

export function useCanEditCatalogue(catalogue: Catalogue) {
  const { sites } = useSitesContext();
  const { user } = useAuthContext();

  const currentSite = sites.find((site) => site.id === catalogue?.site?.id);

  if (!currentSite) return false;

  if (getRoleByPermissions(user?.permission!) === "super-admin") return true;

  if (currentSite.public) {
    return (
      getRoleByPermissions(user?.permission!) === "documenter" ||
      getRoleByPermissions(user?.permission!) === "private-documenter"
    );
  }

  return getRoleByPermissions(user?.permission!) === "private-documenter";
}

export default useCanEdit;
