/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useCategoriesContext } from "../../categories/hooks/use-categories";
import CustomSelect from "../../shared/components/general/custom-select";
import { Category } from "../../shared/types/categories";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

interface CategorySelectorProps {
  initialParent?: Category | null;
  initialChild?: Category | null;
  initialSubChild?: Category | null;
  onParentChange?: (parent: Category | null) => void;
  onChildChange?: (child: Category | null) => void;
  onSubChildChange?: (subChild: Category | null) => void;
}

function CategorySelector({
  initialParent = null,
  initialChild = null,
  initialSubChild = null,
  onParentChange,
  onChildChange,
  onSubChildChange,
}: CategorySelectorProps) {
  const { parentCategories, getCategoriesByParent } = useCategoriesContext();

  const [parent, setParent] = useState<Category | null>(initialParent);
  const [child, setChild] = useState<Category | null>(initialChild);
  const [subChild, setSubChild] = useState<Category | null>(initialSubChild);

  useEffect(() => {
    setParent(initialParent);
  }, [initialParent]);

  useEffect(() => {
    setChild(initialChild);
  }, [initialChild]);

  useEffect(() => {
    setSubChild(initialSubChild);
  }, [initialSubChild]);

  function findCategoryByName(list: Category[] | undefined, name: string) {
    return list?.find((cat) => cat.name === name) || null;
  }

  const handleParentChangeInternal = (value: string | null) => {
    const selectedParent = findCategoryByName(parentCategories!, value || "");
    setParent(selectedParent);
    setChild(null);
    setSubChild(null);

    if (onParentChange) {
      onParentChange(selectedParent);
    }

    // Clear child and subChild since we want explicit selections
    if (onChildChange) {
      onChildChange(null);
    }
    if (onSubChildChange) {
      onSubChildChange(null);
    }
  };

  const handleChildChangeInternal = (value: string | null) => {
    if (!parent) return;

    const possibleChildren = getCategoriesByParent(parent.id);
    const selectedChild = findCategoryByName(possibleChildren, value || "");
    setChild(selectedChild);
    setSubChild(null);

    if (onChildChange) {
      onChildChange(selectedChild);
    }
    if (onSubChildChange) {
      onSubChildChange(null);
    }
  };

  const handleSubChildChangeInternal = (value: string | null) => {
    if (!child) return;

    const possibleSubChildren = getCategoriesByParent(child.id);
    const selectedSubChild = findCategoryByName(
      possibleSubChildren,
      value || ""
    );
    setSubChild(selectedSubChild);

    if (onSubChildChange) {
      onSubChildChange(selectedSubChild);
    }
  };

  const parentItems = parentCategories?.map((t) => t.name) || [];
  const childItems = parent
    ? getCategoriesByParent(parent.id).map((t) => t.name)
    : [];
  const subChildItems = child
    ? getCategoriesByParent(child.id).map((t) => t.name)
    : [];

  return (
    <div aria-label="Breadcrumb" className="flex items-center space-x-10">
      <CustomSelect
        label="Artefacto"
        items={parentItems}
        className="w-40"
        value={parent?.name || ""}
        onChange={handleParentChangeInternal}
      />
      {parent && childItems.length > 0 && (
        <>
          <ChevronRightIcon
            aria-hidden="true"
            className="h-5 w-5 text-gray-400 mt-8"
          />
          <CustomSelect
            label="Clasificación"
            items={childItems}
            className="w-40"
            value={child?.name || ""}
            onChange={handleChildChangeInternal}
          />
        </>
      )}
      {child && subChildItems.length > 0 && (
        <>
          <ChevronRightIcon
            aria-hidden="true"
            className="h-5 w-5 text-gray-400 mt-8"
          />
          <CustomSelect
            label="Subclasificación"
            items={subChildItems}
            className="w-40"
            value={subChild?.name || ""}
            onChange={handleSubChildChangeInternal}
          />
        </>
      )}
    </div>
  );
}

export default CategorySelector;
