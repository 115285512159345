import { passwordRegex } from "./contsants";

export function verifyPasswords(newPwd: string, confirm: string) {
  if (newPwd !== confirm) {
    return "Las claves no coinciden";
  }

  if (!passwordRegex.test(newPwd)) {
    return "La clave debe tener al menos 8 caracteres, una mayúscula, un número y un caracter especial";
  }

  return null;
}
