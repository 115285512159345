import React, { createContext, useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { Category } from "../../shared/types/categories";
import apiService from "../../services/api-service";
import { useAuthContext } from "../../context/AuthContext";

interface ICategoriesContext {
  categories: Category[] | null;
  loading: boolean;
  error: string | null;
  parentCategories: Category[] | null;
  childCategories: Category[] | null;
  subChildCategories: Category[] | null;
  addCategory: (newCategory: Category) => void;
  updateCategory: (updatedCategory: Category) => void;
  deleteCategory: (categoryId: number) => void;
  getCategoriesByParent: (parentId: number) => Category[];
}

const defaultContext: ICategoriesContext = {
  categories: null,
  loading: true,
  error: null,
  parentCategories: null,
  childCategories: null,
  subChildCategories: null,
  addCategory: () => {},
  updateCategory: () => {},
  deleteCategory: () => {},
  getCategoriesByParent: () => [],
};

export const CategoriesContext =
  createContext<ICategoriesContext>(defaultContext);

export const CategoriesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuthContext();
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [parentCategories, setParentCategories] = useState<Category[] | null>(
    null
  );
  const [childCategories, setChildCategories] = useState<Category[] | null>(
    null
  );
  const [subChildCategories, setSubChildCategories] = useState<
    Category[] | null
  >(null);

  const getCategoriesByParent = (parentId: number) => {
    return (
      categories?.filter((category) => category.parentId === parentId) || []
    );
  };

  useEffect(() => {
    async function getCategories() {
      setLoading(true);
      setError(null);
      const { data, error } = await apiService.get("categories");
      if (error) {
        toast.error("Error getting categories");
        setError("Error getting categories");
        setLoading(false);
        return;
      }
      setCategories(data);
      setLoading(false);
    }
    if (user) {
      getCategories();
    }
  }, [user]);

  useEffect(() => {
    if (categories === null) return;
    setParentCategories(categories.filter((category) => category.level === 1));
    setChildCategories(categories.filter((category) => category.level === 2));
    setSubChildCategories(
      categories.filter((category) => category.level === 3)
    );
  }, [categories]);

  const addCategory = (newCategory: Category) => {
    setCategories((prevCategories) => [...(prevCategories || []), newCategory]);
  };

  const updateCategory = (updatedCategory: Category) => {
    setCategories(
      (prevCategories) =>
        prevCategories?.map((category) =>
          category.id === updatedCategory.id ? updatedCategory : category
        ) || []
    );
  };

  const deleteCategory = (categoryId: number) => {
    setCategories(
      (prevCategories) =>
        prevCategories?.filter((category) => category.id !== categoryId) || []
    );
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        loading,
        error,
        parentCategories,
        childCategories,
        subChildCategories,
        addCategory,
        updateCategory,
        deleteCategory,
        getCategoriesByParent,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => {
  return useContext(CategoriesContext);
};
