import React from "react";
import { twMerge } from "tailwind-merge";

type CustomButtonProps = {
  onClick?: () => void;
  className?: string;
  title: string;
  filled?: boolean;
  disabled?: boolean;
  submit?: boolean;
  loading?: boolean;
};

function CustomButton({
  onClick,
  className,
  disabled = false,
  title,
  submit = false,
  loading = false,
  filled = true,
}: CustomButtonProps) {
  return (
    <button
      type={submit ? "submit" : "button"}
      disabled={disabled || loading}
      className={twMerge(
        "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        filled
          ? "bg-gray-700 hover:bg-gray-600 focus-visible:outline-gray-600"
          : "bg-white border border-gray-700 text-gray-700 hover:bg-white hover:ring-1",
        disabled &&
          "bg-gray-300 text-gray-500 cursor-default hover:bg-gray-300",
        className,
        loading && "cursor-wait animate-pulse bg-slate-500"
      )}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default CustomButton;
