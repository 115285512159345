import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import SearchBar from "../general/seach-bar";
import { getRoleByPermissions } from "../../../users/utils/user-utils";
import CustomButton from "../general/custom-button";
import CreateContentDialog from "../general/create-content-dialog";
import getPageName from "../../utils/page";
import { useState } from "react";
import { useSitesContext } from "../../../context/SitesContext";
import { twMerge } from "tailwind-merge";

const userNavigation = [
  { name: "Mi Cuenta", href: "/mi-cuenta" },
  { name: "Ayuda", href: "#" },
  { name: "Contacto", href: "#" },
  { name: "Logout", href: "" },
];

type Props = {
  showCreateButton?: boolean;
};

export default function DasboardTopbar({ showCreateButton = false }: Props) {
  const { logOut, user } = useAuthContext();
  const { sites } = useSitesContext();
  const visibleSites = [...sites.filter((site) => site.visible)];
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const fullName = `${user?.name} ${user?.lastName}`;

  function getGreeting() {
    const date = new Date();

    if (date.getHours() >= 0 && date.getHours() < 12) {
      return "Buenos días";
    }
    if (date.getHours() >= 12 && date.getHours() < 18) {
      return "Buenas tardes";
    }
    if (date.getHours() >= 18 && date.getHours() < 24) {
      return "Buenas noches";
    }
  }

  function handleLogOut() {
    logOut();
    navigate("/");
  }

  return (
    <>
      <Disclosure as="header" className=" shadow-lg">
        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
          <div className="relative flex h-16 justify-between items-center">
            <div className="">
              <p className="text-base font-medium">{getGreeting()}</p>
              <p className="text-sm ">{fullName}</p>
            </div>
            {getRoleByPermissions(user?.permission!) !== "account-admin" && (
              <SearchBar />
            )}

            {/* Profile dropdown */}
            <Menu as="div" className="">
              <div>
                <MenuButton className="relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 hover:ring-pantone focus:ring-offset-2">
                  <img
                    alt=""
                    src={
                      "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                    }
                    className="h-8 w-8 rounded-full"
                  />
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    <NavLink
                      onClick={(e) => {
                        if (item.name === "Logout") {
                          e.preventDefault();
                          handleLogOut();
                        }
                      }}
                      to={item.href}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      {item.name}
                    </NavLink>
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>
          <div className="flex items-center justify-between">
            {getRoleByPermissions(user?.permission!) !== "account-admin" && (
              <nav className="w-[1000px] py-2 overflow-x-auto">
                <div className="flex space-x-4 flex-1">
                  {visibleSites.map((item) => (
                    <NavLink
                      key={item.name}
                      to={`/${item.path}`}
                      className={twMerge(
                        window.location.pathname.includes(item.path)
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                        "inline-flex items-center rounded-md px-3 py-2 text-sm font-medium flex-shrink-0 whitespace-nowrap"
                      )}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </nav>
            )}
            {showCreateButton && (
              <CustomButton
                title="Crear"
                className="w-20 py-2 "
                filled={false}
                onClick={() => setOpen(true)}
              />
            )}
          </div>
        </div>
      </Disclosure>
      <CreateContentDialog
        open={open}
        setOpen={setOpen}
        pageName={getPageName(window.location.pathname)}
      />
    </>
  );
}
