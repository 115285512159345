import React, { useEffect } from "react";
import {
  ArrowUpOnSquareIcon,
  DocumentIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

interface CustomFileUploaderProps {
  onFileChange: (file?: File | null) => void;
}

function FileContainer({
  file,
  onDelete,
}: {
  file: File;
  onDelete: () => void;
}) {
  return (
    <div
      className="w-full rounded-lg bg-[#F2F5F9] p-3 my-2 flex items-center space-x-4"
      key={file.name}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex space-x-2 overflow-x-scroll lg:overflow-x-auto">
          <div className="hidden rounded-lg border border-[#BBB0FF] bg-[#F1EFFF] lg:grid place-items-center  w-10 h-10">
            <DocumentIcon className="w-5 h-5 text-pantone " />
          </div>
          <div className="flex-col flex">
            <span className="text-sm leading-5">{file.name}</span>
            <span className="text-[#97A3B6] text-sm leading-5">
              {file!.size! / 1000 || file!.size! / 1000} KB
            </span>
          </div>

          <XMarkIcon className="w-5 h-5 cursor-pointer" onClick={onDelete} />
        </div>
      </div>
    </div>
  );
}

export default function CustomFileUploader({
  onFileChange,
}: CustomFileUploaderProps) {
  const [file, setFile] = React.useState<File | null>(null);

  //handle drag&drop actions
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedFiles = [...(e.dataTransfer?.files ?? e.target.files ?? [])];

    if (selectedFiles.length === 0) return;

    setFile(selectedFiles[0]);
  };

  useEffect(() => {
    onFileChange(file);
  }, [file, onFileChange]);

  if (file) {
    return <FileContainer file={file} onDelete={() => setFile(null)} />;
  }

  return (
    <div>
      <div
        id="drop_zone"
        className="relative block w-full rounded-lg border-2 space-y-3 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 mb-4 cursor-pointer"
        onClick={() => {
          const imgInput = document.querySelector("#img_input");

          // @ts-ignore
          imgInput?.click();
        }}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDrop(e)}
        onDragEnter={(e) => handleDrop(e)}
        onDragLeave={(e) => handleDrop(e)}
      >
        <>
          <ArrowUpOnSquareIcon className="w-8 h-8 mx-auto  " />
          <p className="text-sm text-pantoneBlack">
            Arrastra o<span className="text-ost-500"> Elige Archivo </span>
            para subir
          </p>
        </>
      </div>
      <input
        type="file"
        className="hidden"
        id="img_input"
        accept="*"
        multiple
        onChange={(e) => handleDrop(e)}
      />
    </div>
  );
}
