import React from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import { Category } from "../../shared/types/categories";
import CategorySelector from "./category-selector";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onItemCreated: (
    name: string,
    briefDescription: string,
    parentCategory: Category | null,
    childCategory: Category | null,
    subchildCategory: Category | null
  ) => Promise<void>;
};

function CreateCatalogueItemDialog({ open, setOpen, onItemCreated }: Props) {
  const [name, setName] = React.useState("");
  const [briefDescription, setBriefDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [parentCategory, setParentCategory] = React.useState<Category | null>(
    null
  );
  const [childCategory, setChildCategory] = React.useState<Category | null>(
    null
  );
  const [subChildCategory, setSubChildCategory] =
    React.useState<Category | null>(null);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    await onItemCreated(
      name,
      briefDescription,
      parentCategory,
      childCategory,
      subChildCategory
    );
    setLoading(false);
    handleClose();
  }

  function handleClose() {
    setName("");
    setBriefDescription("");
    setOpen(false);
  }

  return (
    <CustomDialog
      dialogTitle="Nueva Entrada"
      large
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <p className="text-start font-medium mb-4">Selecciona categorias: </p>
        <div className="flex items-center justify-start space-x-6 mb-5 border-b pb-4 border-b-gray-500">
          <CategorySelector
            initialParent={parentCategory}
            initialChild={childCategory}
            initialSubChild={subChildCategory}
            onParentChange={(parent) => setParentCategory(parent)}
            onChildChange={(child) => setChildCategory(child)}
            onSubChildChange={(subChild) => setSubChildCategory(subChild)}
          />
        </div>

        <div className="space-y-4">
          <p className="text-start font-medium mb-4">Detalles:</p>

          <CustomInput
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <CustomInput
            placeholder="Descripción breve"
            value={briefDescription}
            onChange={(e) => setBriefDescription(e.target.value)}
            required
          />
        </div>

        <div className="mt-20 flex items-center justify-end gap-x-2">
          <CustomButton title="Cancelar" filled={false} onClick={handleClose} />
          <CustomButton title="Crear" submit loading={loading} />
        </div>
      </form>
    </CustomDialog>
  );
}

export default CreateCatalogueItemDialog;
