import React, { useEffect } from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import CustomFileUploader from "../../shared/components/general/custom-file-uploader";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import apiService from "../../services/api-service";
import { MediaFile } from "../../shared/types/section";
import { CatalogueItem } from "../../shared/types/catalogues";
import KeywordInput from "../../wiki/components/KeyWordInput";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  catalogueItem: CatalogueItem;
  onFileCreated: (MediaFile: MediaFile) => void;
  MediaFileOnView?: MediaFile | null;
  onClose: () => void;
  onFileUpdated: (MediaFile: MediaFile) => void;
};

function MediaFileDialog({
  open,
  setOpen,
  onClose,
  catalogueItem,
  onFileCreated,
  MediaFileOnView,
  onFileUpdated,
}: Props) {
  const [fileToUpload, setFileToUpload] = React.useState<
    File | null | undefined
  >(null);
  const [loading, setLoading] = React.useState(false);
  const [fileOwner, setFileOwner] = React.useState("");
  const [documentedBy, setDocumentedBy] = React.useState("");
  const [fileKeywords, setFileKeywords] = React.useState<string[]>([]);
  const [fileDescription, setFileDescription] = React.useState("");

  useEffect(() => {
    if (MediaFileOnView) {
      setFileOwner(MediaFileOnView.owner || "");
      setDocumentedBy(MediaFileOnView.documentedBy || "");
      setFileKeywords(MediaFileOnView.keywords || []);
      setFileDescription(MediaFileOnView.description || "");
    } else {
      cleanFields();
    }
  }, [MediaFileOnView]);

  function cleanFields() {
    setFileOwner("");
    setDocumentedBy("");
    setFileKeywords([]);
    setFileDescription("");
  }

  function validateForm(validateFile = true) {
    if (validateFile && !fileToUpload) {
      toast.error("Debe seleccionar un archivo");
      return false;
    }

    return true;
  }

  async function createMediaFile() {
    const isValidForm = validateForm();
    if (!isValidForm) {
      return;
    }

    setLoading(true);
    const { data, error } = await apiService.uploadFile<MediaFile>(
      `catalogues/${catalogueItem.catalogue!.id}/items/${
        catalogueItem.id
      }/files`,
      fileToUpload!,
      "post",
      {
        owner: fileOwner,
        documentedBy: documentedBy,
        keywords: fileKeywords,
        description: fileDescription,
      }
    );

    if (error) {
      setLoading(false);
      toast.error("Error al subir el archivo");
      return;
    }

    if (error) {
      toast.error("Error al subir el archivo");
      setLoading(false);
      return;
    }
    handleClose();
    onFileCreated(data!);
    setOpen(false);
    toast.success("Archivo subido correctamente");
    setLoading(false);
  }

  async function handleUpdateMediaFile() {
    const isValidForm = validateForm(false);

    if (!isValidForm) {
      return;
    }
    setLoading(true);
    if (fileToUpload) {
      const { error: uploadError } = await apiService.uploadFile<MediaFile>(
        `catalogues/${catalogueItem.catalogue!.id}/item/${
          catalogueItem.id
        }/files/${MediaFileOnView!.id}`,
        fileToUpload!,
        "put"
      );

      if (uploadError) {
        toast.error("Error al actualizar el archivo");
        setLoading(false);
        return;
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    if (MediaFileOnView == null) {
      createMediaFile();
    } else {
      handleUpdateMediaFile();
    }
  }

  function handleClose() {
    cleanFields();
    onClose();
  }

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      dialogTitle={MediaFileOnView ? "Editar Archivo" : "Nuevo Archivo"}
      onClose={handleClose}
    >
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="flex w-full space-x-4 justify-between">
          <CustomInput
            placeholder="Dueño"
            className="w-[200px]"
            onChange={(e) => setFileOwner(e.target.value)}
            required
            value={fileOwner}
          />
          <CustomInput
            placeholder="Documentador"
            className="w-[200px]"
            required
            value={documentedBy}
            onChange={(e) => setDocumentedBy(e.target.value)}
          />
        </div>
        <KeywordInput
          initialKeywords={MediaFileOnView?.keywords || []}
          onKeywordsChange={(keywords) => setFileKeywords(keywords)}
        />
        <textarea
          className="w-full h-16 p-2 rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          placeholder="Descripción"
          required
          value={fileDescription}
          onChange={(e) => setFileDescription(e.target.value)}
        />
        <CustomFileUploader
          onFileChange={(newFile) => setFileToUpload(newFile)}
        />
        {MediaFileOnView != null ? (
          <CustomButton
            title="Guardar cambios"
            className={twMerge("w-full", loading ? "cursor-progress" : "")}
            submit
            disabled={loading}
            loading={loading}
          />
        ) : (
          <CustomButton
            title="Agregar"
            className={twMerge("w-full", loading ? "cursor-progress" : "")}
            submit
            disabled={loading}
            loading={loading}
          />
        )}
      </form>
    </CustomDialog>
  );
}

export default MediaFileDialog;
