import React from "react";
import LoadingPage from "../../shared/components/general/loading-page";
import { useSitesContext } from "../../context/SitesContext";
import { useNavigate } from "react-router-dom";

function SplashScreen() {
  const { sites } = useSitesContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    const firstValidSite = sites.find((site) => site.public);

    if (firstValidSite) {
      navigate(`/${firstValidSite.path}`);
    }
  }, [sites, navigate]);

  return <LoadingPage />;
}

export default SplashScreen;
