import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { getRoleByPermissions } from "../users/utils/user-utils";

interface AuthValidatorI {
  href: string;
}

export default function AuthValidator({ href }: AuthValidatorI) {
  const { user } = useAuthContext();

  if (!user) {
    return <Navigate to="/" />;
  }

  const userRole = getRoleByPermissions(user.permission);

  if (href === "/mi-cuenta") {
    return <Outlet />;
  }

  // Account-admin can only access "/administracion"
  if (userRole === "account-admin") {
    if (href === "/administracion") {
      return <Outlet />;
    } else {
      return <Navigate to="/administracion" />;
    }
  }

  // Super admin and admin have access to "/administracion"
  if (href === "/administracion") {
    if (userRole === "admin" || userRole === "super-admin") {
      return <Outlet />;
    } else {
      return <Navigate to="/home" />;
    }
  }

  // Other users can access other routes
  return <Outlet />;
}
