import { useState, useEffect } from "react";
import { Section } from "../../shared/types/section";
import apiService from "../../services/api-service";
import { Catalogue } from "../../shared/types/catalogues";
import { useSitesContext } from "../../context/SitesContext";

interface State {
  sections?: Section[] | null;
  catalogues?: Catalogue[] | null;
  loading: boolean;
  error: boolean;
}

const initalState = {
  sections: [],
  catalogues: [],
  loading: true,
  error: false,
};

const omitedPages = [
  "busqueda",
  "",
  "olvide-clave",
  "usuarios",
  "mi-cuenta",
  "administracion",
];

export const useSections = (page: string) => {
  const [state, setState] = useState<State>(initalState);
  const { sites } = useSitesContext();
  const currentSite = sites.find((site) => site.path === page);

  useEffect(() => {
    async function getPageData(page: string) {
      if (omitedPages.includes(page)) return;

      const [sectionsResponse, cataloguesResponse] = await Promise.all([
        apiService.get<Section[]>("sections", { siteId: currentSite?.id }),
        apiService.get<Catalogue[]>(`catalogues/${currentSite?.id}`),
      ]);

      setState({
        loading: false,
        sections: sectionsResponse.error ? null : sectionsResponse.data,
        catalogues: cataloguesResponse.error ? null : cataloguesResponse.data,
        error: sectionsResponse.error || cataloguesResponse.error,
      });
    }
    setState(initalState);
    if (currentSite) {
      getPageData(page);
    }
  }, [page, currentSite]);

  return state;
};
