import React, { useEffect } from "react";
import CategoryCard from "./components/category-card";

import LoadingCategoryCard from "./components/loading-category-card";
import { Category } from "../shared/types/categories";
import CategoriesDialog from "./components/categories-dialog";
import { useCategoriesContext } from "./hooks/use-categories";

function CategoriesPage() {
  const {
    parentCategories,
    loading,
    error,
    addCategory,
    getCategoriesByParent,
    updateCategory,
  } = useCategoriesContext();
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] =
    React.useState<Category | null>(null);
  const [actionLevel, setActionLevel] = React.useState(1);
  const [selectedParent, setSelectedParent] = React.useState<Category | null>(
    null
  );

  const [selectedSubChild, setSelectedSubChild] =
    React.useState<Category | null>(null);

  function openActionModal(level: number, category: Category | null) {
    if (!category) {
      setSelectedCategory(null);
    }
    setActionLevel(level);
    setSelectedCategory(category);
    setIsActionModalOpen(true);
  }

  useEffect(() => {
    setSelectedSubChild(null);
  }, [selectedParent]);

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-between">
        <LoadingCategoryCard />
        <LoadingCategoryCard />
        <LoadingCategoryCard />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const childCategories = getCategoriesByParent(selectedParent?.id || 0);
  const subChildCategories = getCategoriesByParent(selectedSubChild?.id || 0);

  return (
    <div className="flex items-start justify-start space-x-4">
      <CategoryCard
        categories={parentCategories!}
        level={1}
        openActionModal={openActionModal}
        onSelectedCategoryChange={(category) => setSelectedParent(category)}
      />
      {selectedParent && (
        <CategoryCard
          categories={childCategories}
          level={2}
          openActionModal={openActionModal}
          onSelectedCategoryChange={(category) => setSelectedSubChild(category)}
        />
      )}
      {selectedParent && selectedSubChild && (
        <CategoryCard
          categories={subChildCategories}
          level={3}
          openActionModal={openActionModal}
          onSelectedCategoryChange={() => {}}
        />
      )}
      <CategoriesDialog
        onClose={() => setSelectedCategory(null)}
        level={actionLevel}
        onCategoryCreated={addCategory}
        onCategoryUpdated={updateCategory}
        open={isActionModalOpen}
        setOpen={setIsActionModalOpen}
        category={selectedCategory}
        selectedParentCategory={selectedParent}
        selectedSubchildCategory={selectedSubChild}
      />
    </div>
  );
}

export default CategoriesPage;
